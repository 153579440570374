import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="नको लालसा द्रव्याची (अभंग)" />
        <HeaderGeneric title="इतर साहित्य" adhyaay='नको लालसा द्रव्याची (अभंग)'/>
        <div id="main">
          <section id="content" className="main">
            <p>
॥ श्री गजानन महाराज प्रसन्न ॥< br />
॥ श्री आठवले गुरू प्रसन्न ॥< br />
< br />
गुरुवार, १४ जून १९९०.< br />
< br />
नको लालसा द्रव्याची । जी निकामीच ठरायची ।< br />
द्रव्याने तुंबडी भरायची । सोय जिवंत मरणाची ।< br />
दुराचारे कमवायची । मनःशांती भंग व्हावयची ।< br />
पर द्रव्य हिरावण्याची । गणा सांगे वेळ मरणाची ॥ १ ॥< br />
< br />
नको द्रव्य लालसा फार । लालसेत न कधी तरणार ।< br />
द्रव्या लालसाच जाळणार ।  तिच सदा होरपळणार ।< br />
जो द्रव्य लालसेत रहाणार । आसक्ती भावात जगणार ।< br />
बाळगुन काय मिळवणार ? । गणा सांगे सर्वच गमावणार ॥ २ ॥< br />
< br />
द्रव्य किती कमवावे ? । प्राक्तनावर सोपवावे ।< br />
आहे त्यात संतुष्ट व्हावे । समाधानी वृत्तीने जगावे ।< br />
परमार्थी धन खर्चावे । स्वकष्टे जे मिळवावे ।< br />
ऐसे द्रव्य कमवावे । गणा सांगे निवांत झोपावे ॥ ३ ॥< br />
< br />
नका जाळु चित्तास । द्रव्य हव्यास पूर्तीस ।< br />
निकामे समजावे त्यास । जे दूर लोटे शांतीस ।< br />
इतुके जवळ करा त्यास । उपयुक्त मनःशांतीस ।< br />
द्रव्याचा संपता हव्यास । गणा सांगे शांती त्यास ॥ ४ ॥< br />
< br />
नका हिरावु परद्रव्य । नको त्यात वेळ व्यय ।< br />
परद्रव्य अक्षय । तेणे शांतीचा क्षय ।< br />
कधी न तुम्ही निर्भय । काळाचे सदा भय ।< br />
रहाता नाममय । गणा सांगे निर्भय ॥ ५ ॥< br />
< br />
द्रव्य लालसे करता । नका जगु त्याकरता ।< br />
द्रव्य चिंतन येताजाता । लोभ बळावे येता जाता ।< br />
अतीलोभ त्याचा होता । सर्व द्रव्यातच मोजता ।< br />
अतीलोभ जाळे चित्ता । गणा सांगे ठसवा चित्ता ॥ ६ ॥< br />
< br />
नको द्रव्याचे मानकरी । तेणे व्हाल अहंकारी ।< br />
अहंकाराची येरझारी । शांती भंग सदा करी ।< br />
द्रव्य सारे संपल्यावरी । चिंता काळजीच उरी ।< br />
नाम येऊद्या मुखावरी । गणा सांगे नामच उद्धरी ॥ ७ ॥< br />
< br />
द्रव्य लालसे जगता । पूर्ती साठी भटकता ।< br />
पापाची बीजे पेरता । पापफळेच चाखता ।< br />
कटुता अनुभवता । लालसा दूर लोटता ।< br />
नाम भजा येता जाता । गणा सांगे त्या करिता ॥ ८ ॥< br />
< br />
पैसा अडका बेताचा । नको मार्ग हव्यासाचा ।< br />
पोटापुरते जगण्याचा । तेणे योग हरीनामाचा ।< br />
मार्ग सदा हव्यासाचा । घात मनःशांतीचा ।< br />
गणा सांगे हरीनामाचा । मार्ग पैलतीराचा ॥ ९ ॥< br />
< br />
नको लोभ द्रव्यावरी । लोभाने शांती जाते दूरी ।< br />
शांतीस लोटल्यावरी । काय रहाणार उरी ? ।< br />
अशांती असता उरी । नाना क्लुप्त्या आचरी । < br />
गणा सांगे आजवरी । कुणी न उद्धरले संसारी ॥ १० ॥< br />
< br />
किती पैसा कमवावा ? । त्यात जीव रमवावा ।< br />
जाळण्या इतुका नसावा । पेलण्या इतुका असावा ।< br />
प्रपंचा पुरता नसावा । परमार्थी खर्च व्हावा ।< br />
गणा सांगे चित्ती ठसवा । धर्माचरणे द्रव्य कमवा ॥ ११ ॥< br />
< br />
द्रव्याच्या मागे पडता । हव्यासात अडकता ।< br />
नकळत दास होता । त्याची जी जी करता ।< br />
अशांतीनेच जगता । जीव बेजार करता ।< br />
गणा सांगे त्याकरिता । नामच जीवनी त्राता ॥ १२ ॥< br />
< br />
नको द्रव्याचेच मानकरी । कुणी न तरले आजवरी ।< br />
लोभाची बीजे पेरल्यावरी । लोभाची फळे उदरी ।< br />
तीच खाता वरचेवरी । लोभ बळावतो भारी ।< br />
गणा सांगे आजवरी । नाना यातना अंतरी ॥ १३ ॥< br />
< br />
मी नि माझ्याकरिता । किती हव्यास करता ! ।< br />
अशाश्वतीतच रमता । त्याच्याच मागे पडता ।< br />
पूर्ततेसाठी धांवा करता । कोण ठरे तुम्हां त्राता ? ।< br />
गणा सांगे ठसवा चित्ता । शाश्वतच खरा त्राता ॥ १४ ॥< br />
< br />
द्रव्य कितीही कमवता । तेणे न शांती चित्ता ।< br />
अशांत मने जगता । केवळ हव्यासाकरता ।< br />
द्रव्यात इतुके धुंद होता । हव्यास जाळतो, विसरता ।< br />
अंतरात्म्यास ऐकता । गणा सांगे शांतीने जगता ॥ १५ ॥< br />
< br />
किती कमवावे आपण ? । हे ही न जाणता आपण ।< br />
द्रव्याचे संपता इंधन । प्राप्तीसाठी ज्वलन ।< br />
केवळ द्रव्याने जीवन । कधी न परीपूर्ण जीवन ।< br />
गणा सांगे नामस्मरण । ठरते तरण्या जीवन ॥ १६ ॥< br />
< br />
द्रव्याचा फाजील साठा । फाजील जगण्या करता ।< br />
बेताचे द्रव्य असता । नामस्मरणी रमता ।< br />
फाजीलपणे मिळवता । फाजीलपणे घालवता ।< br />
गणा सांगे त्याकरिता । नको त्याचा फाजील साठा ॥ १७ ॥< br />
< br />
द्रव्यच न कमवता । त्यासाठी जीवन जाळता ।< br />
काय मिळते ? पुसा चित्ता । मनःशांती मिळवता ।< br />
नामावर सोपवता । नामच जीवनी त्राता ।< br />
गणा सांगे त्या करिता । संत बोल आठवा आता ॥ १८ ॥< br />
< br />
नका होऊ धुंद द्रव्यात । कुणी न तरले त्यात ।< br />
हव्यासाच्या जाळ्यात । नका गुरफटु त्यात ।< br />
जीव बेजार करण्यात । काय मिळवता त्यात ? ।< br />
गणा सांगे रहावे नामात । वागावे त्याचे आदेशात ॥ १९ ॥< br />
< br />
नको तुलना द्रव्याची । ऐशा मार्गे जगण्याची ।< br />
तुलना घातक ठरायची । वेळ इमले कोसळण्याची ।< br />
परद्रव्य हिरावण्याची । वेळ जिवंत मरण्याची ।< br />
गणा सांगे संत बोलाची । कीमया जीवन जगण्याची ॥ २० ॥< br />
< br />
- गणामहाराज
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
